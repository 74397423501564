import { NgTemplateOutlet } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { WINDOW } from '@bs24/universal/window.service';
import { EnvConfig } from '@bs24/core/models/environment-config';
import { IFooterLicensee } from '@bs24/core/models/footer';
import { TrustedPipe } from '@bs24/core/pipes/trusted.pipe';
import { Observable, Subscription } from 'rxjs';

interface License extends Partial<IFooterLicensee> {
  href: string;
  image?: string;
  alt: string;
}

const licenses: License[] = [
  { typeId: 2, href: 'https://www.gamingboard.go.tz/', image: 'tanzania-gb.png', alt: 'tanzania gaming board' },
  { typeId: 3, href: 'https://nlrc-gov.ng/', image: 'nlrc.png', alt: 'Nigeria Lottery Commission' },
  { typeId: 4, href: 'https://www.gamingboard.org.mw/', image: 'malawi-gaming-board.png', alt: 'Malawi Gaming Board' },
  { typeId: 5, href: null, image: 'drc.png', alt: 'Ministère des Sports et Loisirs' },
  { typeId: 6, href: null, image: 'betting-control-board-zambia.png', alt: 'Betting Control Board Zambia' },
  { typeId: 7, href: 'https://gamingcommission.gov.gh/', image: 'gaming-commission-ghana.png', alt: 'Gaming Commission Ghana' },
  { typeId: 8, href: null, image: 'ethiopia.png', alt: 'ethiopia' },
  { typeId: 9, href: 'https://www.mef.gob.pa/secretaria-ejecutiva-junta-de-control-de-juegos/', image: 'jcj-panama.jpg', alt: 'Junta de Control de Juegos' },
  { typeId: 10, href: 'http://onjn.gov.ro/', image: 'onjn-romania.png', alt: 'ONJN Romania' },
  { typeId: 11, href: 'https://bclb.go.ke/', alt: 'BCLB Kenya' },
  { typeId: 12, href: null, alt: 'Haiti' },
  { typeId: 16, href: null, alt: 'Uganda' },
  { typeId: 18, href: null, alt: 'Swaziland' },
  { typeId: 19, href: null, alt: 'CEG' }
];

@Component({
  selector: 'license',
  templateUrl: './license.component.html',
  imports: [
    NgTemplateOutlet,
    TrustedPipe
  ],
  standalone: true
})

export class LicenseComponent implements OnDestroy, OnInit {

  @Input()
  license: IFooterLicensee;

  currentLicense: License;

  subs = new Subscription();

  constructor(private renderer: Renderer2, @Inject(WINDOW) private window: Window, private conf: EnvConfig) {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    if (this.license.typeId === 1 && this.license.link && this.conf.production) {

      this.subs.add(this.load(this.license.link).subscribe({
        next: () => (this.window[`apg-${this.license.sealId}`.replace(/-/g, '_')]).init(),
        error: err => console.error(err)
      }));

    } else {
      this.currentLicense = licenses.find(l => l.typeId === this.license.typeId);
    }

  }

  private load(scriptSrc) {
    return new Observable(sub => {

      const scriptEl = this.renderer.createElement('script');
      scriptEl.type = 'text/javascript';
      scriptEl.src = scriptSrc;
      scriptEl.onload = () => sub.next();
      scriptEl.onerror = e => sub.error(e);

      this.renderer.appendChild(this.window.document.head, scriptEl);
    });

  }

}
