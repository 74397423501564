import { NgTemplateOutlet } from '@angular/common';
import { Component, OnDestroy, WritableSignal } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MenuService } from '@bs24/cms/services/menu.service';
import { SvgIconDirective } from '@bs24/core/directives/svg-icon.directive';
import { IMe } from '@bs24/core/models/me';
import { IMenu, IMenuItem } from '@bs24/core/models/menu';
import { AuthService } from '@bs24/core/services/auth.service';
import { WebappService } from '@bs24/core/services/webapp.service';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BalanceComponent } from '../../components/balance/balance.component';
import { ClockComponent } from '../../components/clock/clock.component';
import { MenuComponent } from '../../components/menu/menu.component';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    NgbDropdown,
    NgbDropdownToggle,
    SvgIconDirective,
    NgbDropdownMenu,
    NgbDropdownItem,
    TranslateModule,
    ClockComponent,
    //LanguageSelectorComponent,
    MenuComponent,
    NgTemplateOutlet,
    //SsrNorenderDirective,
    BalanceComponent
  ]
})
export class HeaderComponent implements OnDestroy {

  me: IMe;
  userProfile!: WritableSignal<IMe | undefined>;
  isThemeSwitcherAllowed: boolean;
  subs = new Subscription();
  lang: string;
  menu: IMenuItem;
  loggedMenu: IMenu = {
    name: 'logged',
    items: [
      {
        label: 'my-profile',
        routerLink: 'me/user/general',
        icon: 'user'
      },
      {
        label: 'history',
        routerLink: 'me/history/transactions',
        icon: 'archive'
      }/*,
      {
        label: 'referral',
        routerLink: 'me/referral/overview',
        icon: 'code-branch'
      }*/,
      {
        label: 'deposit',
        routerLink: 'me/cashier/deposit',
        icon: 'credit-card'
      }
    ]
  };


  constructor(wa: WebappService, private authService: AuthService, private modal: NgbModal, menuService: MenuService, translate: TranslateService) {
    this.userProfile = authService.currentProfile;
    this.isThemeSwitcherAllowed = wa.webappConfig().features.themeSwitcher;
    this.lang = translate.currentLang;
    menuService.loadedSideMenu.subscribe(menu => {
      this.menu = menu;
    });

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Logout method
   */
  async logout() {
    const ref = await import('../../modals/confirmation/confirmation.modal').then(c => {
      return this.modal.open(c.ConfirmationModal);
    });
    ref.componentInstance.text = 'logout-confirmation';

    ref.result.then(res => {
      if (res) {
        this.authService.logout().subscribe({});
      }

      this.modal.dismissAll();
    });
  }

}
