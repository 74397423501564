import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class CustomDateAdapter implements NgbDateAdapter<string> {
  /**
   * function converts time to readable value for the Datepicker
   * @param value {string} a date in string
   */
  fromModel(value: string): NgbDateStruct | null {
    if (value) {
      const jsDate = new Date(value);
      return {
        year: jsDate.getFullYear(),
        month: jsDate.getMonth() + 1,
        day: jsDate.getDate()
      };
    }
    return null;
  }

  /**
   * function for returning us the date readable for the Datepicker
   * @param date
   */
  toModel(date: NgbDateStruct | null): string | null {
    if (date) {
      const jsDate = new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0);
      // this is done avoid 30 -> 1930 conversion
      if (!isNaN(jsDate.getTime())) {
        jsDate.setFullYear(date.year);
      }
      return jsDate.toISOString();
    }
    return null;
  }

}
