<div [display]="showLabel ? 'static':'dynamic'" ngbDropdown [placement]="placement">
  <button [class.form-select]="showLabel" class="btn border-0 dropdown-toggle hide-arrow btn-xs" ngbDropdownToggle title="language">
    <!--<i class="flag-icon flag-icon-{{ selectedLanguage.code | lowercase }}"></i>-->
    <svg class="flag-icon" lib="flags" [icon]="selectedLanguage.code"></svg>
    @if (showLabel) {
      <span class="ms-50 me-1">{{ selectedLanguage.name }}</span>
    }
  </button>
  @if (languages) {
    <div aria-labelledby="dropdown-flag" class="language-selection" ngbDropdownMenu>
      @for (lang of languages; track lang.code) {
        <a [href]="getLink(lang.code)" [class.bg-secondary]="this.currentLang === lang.code" ngbDropdownItem>
          <!--<i class="flag-icon flag-icon-{{ lang.code }}"></i>-->
          <svg lib="flags" [icon]="lang.code"></svg>
          <span class="text-capitalize ms-50">{{ lang.name }}</span>
        </a>
      }
    </div>
  }
</div>
