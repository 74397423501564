import { HttpEvent, HttpEventType, HttpHandlerFn, HttpHeaders, HttpRequest, HttpResponse, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, inject } from '@angular/core';
import { WebappService } from '@bs24/core/services/webapp.service';
import { WINDOW } from '@bs24/universal/window.service';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { ILanguage } from './models/currency';
import { EnvConfig } from './models/environment-config';
import { AuthService } from './services/auth.service';
import { SettingsService } from './services/settings.service';

/**
 * global const variable
 */

/**
 * class for setting interceptor and setting required headers
 */
export function configFactory<T>(req: HttpRequest<T>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  /**
   * hash {string}
   */
  let hash = '';
  const window = inject<Window>(WINDOW);
  const config = inject(EnvConfig);
  const authService = inject(AuthService);
  const settingsService = inject(SettingsService);

  const defaultLanguage = config.languages.find(l => l.isDefault) as ILanguage;
  let settings = settingsService.settings();

  // if (window.navigator) {
  //   const fpPromise = FingerprintJS.load();
  //   fpPromise.then(fp => fp.get()).then(result => {
  //     hash = result.visitorId;
  //     settingsService.saveSetting({ xDeviceId: result.visitorId });
  //   });
  // }

  const isMobile = window?.isMobile;

  if (!settings?.timeZone) {
    let timeZone;
    if (Intl.DateTimeFormat) {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      //console.log(timeZone)
    } else {
      // Fallback al fuso orario predefinito del sistema o UTC
      if (typeof Intl === 'undefined') {
        // Fallback a UTC
        timeZone = config.timeZone;
      } else {
        // Fallback al fuso orario predefinito del sistema
        timeZone = config.timeZone;
      }
    }
    settingsService.saveSetting({timeZone: timeZone});
  }


  /**
   * this intercept is responsible for default headers setup, and for the user authentication functionalities
   * @param req
   * @param next
   */

  const currentLang: string = window?.location.pathname.split('/')[1] || defaultLanguage.code;

  let headers = new HttpHeaders({'Accept-Language': currentLang});

  if (isMobile) {
    headers = headers.append('Mobile', '1');
  }

  if (settings.timeZone) {
    headers = headers.append('X-Time-Zone', settings.timeZone);
  }

  if (hash) {
    headers = headers.append('X-Device-ID', hash);
  }

  if (config.currency) {
    headers = headers.append('DisplayCurrencyId', `${config.currency.id}`);
  }

  const sid = authService.token;

  if (sid) {
    headers = headers.append('Authorization', `Bearer ${sid}`);
  }


  headers = headers.append('BookmakerId', `${config.bookmakerId}`);
  headers = headers.append('WebAppId', `${config.webAppId}`);

  // if(!this.config.production){
  //   headers = headers.append('X-TestMode', `true`);
  // }

  return next(req.clone({headers})).pipe(
    catchError(event => {
      switch (event.status) {
        case 401:
          authService.logout('forced', event.error.message);
          break;
        case 422:
          // if string contains operation not allowed
          if (event.error.message.startsWith('[Operation not allowed')) {
            authService.logout('forced', 'not-authorized');
          }
          break;
        case 500:
          authService.logout('forced', 'not-authorized');
          //this.appSettingsService.globalErrors$.next({key: event.status, value: event.error});
          break;
      }
      return throwError(() => ({
        status: {
          code: event.status,
          text: event.statusText
        },
        message: event.error.message,
        details: event.error.details,
        url: event.url
      }));
    }),
    filter((r): r is HttpResponse<T> => r.type === HttpEventType.Response),
    map((r: HttpResponse<T>) => {
      /* const responseBody = r.body as { data: T };
       return r.clone({body: responseBody.data});*/
      if (r.body && typeof r.body === 'object' && 'data' in r.body) {
        const responseBody = r.body as { data: T };
        return r.clone({body: responseBody.data});
      } else {
        return r;
      }
    })
    //map((r: HttpResponse<T>) => r.body && 'data' in r.body ? r.clone({body: (r.body as any).data}) : r)
  );

}

export const coreProviders = (config: EnvConfig) => {

  if (!config.production) {
    const [prjStyle, vrsStyle, bmStyle, waStyle] = [
      'background-color:#0AA; color:#FFF; font-style:italic; font-size:1.8em',
      'background-color:#F55; font-size:1.2em',
      'background-color:#555',
      'background-color:#898'
    ];
    console.log(`%c ${config.project} \x1B[m %c v${config.appVersion} \x1B[m %c ${config.bookmakerId} \x1B[m %c ${config.webAppId} `, prjStyle, vrsStyle, bmStyle, waStyle);
  }

  return [
    provideHttpClient(withInterceptors([configFactory]), withFetch()),
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [WebappService],
      useFactory: (wa: WebappService) => () => wa.webapp()
    },
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
    {
      provide: EnvConfig,
      useValue: config
    }
  ];
};

