import { NgClass } from '@angular/common';
import { Component, Input, OnInit, WritableSignal } from '@angular/core';
import { IMe } from '@bs24/core/models/me';
import { MoneyFormatPipe } from '@bs24/core/pipes/money-format.pipe';
import { AuthService } from '@bs24/core/services/auth.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'balance',
  templateUrl: './balance.component.html',
  styleUrl: 'balance.component.scss',
  imports: [
    MoneyFormatPipe,
    TranslateModule,
    NgClass
  ],
  standalone: true
})

export class BalanceComponent implements OnInit {
  userProfile: WritableSignal<IMe | undefined>;

  @Input()
  mode: 'hover' | 'exploded' = 'exploded';
  onHover = false;
  bonusBalance = 0;

  constructor(authService: AuthService) {
    this.userProfile = authService.currentProfile;

    this.userProfile().wallets[0].balance.bonuses?.reduce((acc, item) => {
      acc += item.available;
      return acc;
    }, this.bonusBalance);
  }

  ngOnInit() {
  }
}
