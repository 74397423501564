import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, Router } from '@angular/router';
import { WebappService } from '@bs24/core/services/webapp.service';

import { Observable } from 'rxjs';
import { EnvConfig } from '../models/environment-config';


/**
 * this guard is to check if the website can be visited from a certain country
 */
export const GeoMainGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot) => {

  if (route.routeConfig?.path === 'self-contained/country-restricted') {
    return true;
  }

  const webappService = inject(WebappService);
  const conf = inject(EnvConfig);
  const router = inject(Router);

  return new Observable<boolean>((subscriber) => {

    webappService?.wa$.subscribe({
      next: webApp => {
        //console.log(webApp.visitor)
        //const found = bs.siteconfig.exclude_countries.includes(bs.country.toUpperCase()); // try belize 'blz' to check restriction

        if (webApp.visitor.geoBlocked && conf.production) {
          // subscriber.error();
          const lang = route.paramMap.get('lang');
          return router.navigate([lang, 'self-contained', 'country-restricted']);
        }

        return subscriber.next(true);
      }
    });
  });
};
