<nav class="navbar bg-primary">
  <ul class="d-flex justify-content-between mx-25 gap-1 w-100">
    @for (item of menu; track $index) {
      @if (item?.visible ? item.visible() : true) {
        <li [class]="item.className">
          @if (item.command) {
            <button class="btn btn-secondary d-block" (click)="item.command()">
              <svg class="fill-current" [icon]="item.icon" lib="solid"></svg>
            </button>
          } @else {
            <a [routerLink]="lang+'/'+item.routerLink" class="btn btn-secondary d-block" routerLinkActive="active">
              <svg class="fill-current" [icon]="item.icon" lib="solid"></svg>
            </a>
          }
        </li>
      }
    }
  </ul>
</nav>
