import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { ILanguage } from '../models/currency';
import { Language } from '@bs24/core/models/environment-config';

/**
 * guard checks if :lang is provided in the route, if not navigates to 404 page
 */
export const LangGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const lang = inject(Language);
  const router = inject(Router);

  /**
   * interface guard deciding if a route can be activated, this is main guard if lang doesn't exist won't load any route ex: /en/home
   */
  const ican = route.data.langs.some((l: ILanguage) => l.code === route.params.lang);

  return ican || router.navigate([lang, '404']);
};
