<!--<pre>{{footer | json}}</pre>-->

<ng-template #externalTpl let-link>
  <a [href]="link.url" target="_blank">{{ link.label }}</a>
</ng-template>

<ng-template #linkTpl let-link>
  <a [routerLink]="'/' + lang + link.routerLink">{{ link.label }}</a>
</ng-template>

<ng-template #iconsDisplay let-payments>
  <a [href]="payments.link" target="_blank">
    <svg [icon]="payments.icon | lowercase" class="footer-social-icon footer-social-icon-{{payments.icon}}"
         lib="brands"></svg>
  </a>
</ng-template>

<ng-template #staticTpl let-payment>
  <a [routerLink]="['/', lang, 'payments']">
    <svg [size]="{w:80, h: 26}" [icon]="payment.icon" lib="payments"></svg>
  </a>
  <!--<div [style.backgroundImage]="'url(\'assets/images/payments/svg/'+payment.icon+'.svg\')'"></div>-->
</ng-template>


<div class="footer p-1">
  <div class="container-xl">
    <div class="payments-paragraph d-flex justify-content-center mt-2">
      <h3>MULTIPLE WAYS TO DEPOSIT & WITHDRAW</h3>
    </div>
    @if (footer?.payments.length) {
      <ul class="footer-payments d-flex flex-wrap justify-content-center my-3">
        @for (payment of footer?.payments; track $index) {
          @if (payment.icon) {
            <div class="m-25 px-75 py-25">
              <ng-template [ngTemplateOutletContext]="{$implicit: payment}" [ngTemplateOutlet]="payment.link ? linkTpl : staticTpl"></ng-template>
            </div>
          }
        }
      </ul>
    }

    <div class="social-paragraph d-flex justify-content-center">
      <h4>World of online slots, betting games, live table games, sports betting and more!</h4>
    </div>
    <ul class="footer-social d-flex gap-1 justify-content-center align-items-center">
      @for (social of footer.socials; track $index) {
        <li>
          <ng-container *ngTemplateOutlet="iconsDisplay; context: {$implicit: social}"></ng-container>
        </li>
      }
    </ul>
    <div class="accordion p-0 d-flex flex-column" id="accordionGroup">
      @for (accordion of accordions; track $index) {
        <div ngbAccordion>
          <div ngbAccordionItem>
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>{{ accordion.label }}</button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <small>{{ accordion.content }}</small>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
    <div class="row my-2 disclaimer">
      <div class="col-12">
        <h5 class="h5" translate>disclaimer</h5>
        <p class="mt-50 m-0">{{ footer.disclaimer }}</p>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-end my-2 license-paragraph">
      @if (footer?.license.active) {
        <license [license]="footer.license"></license>
      }
    </div>
    <!--      <div class="d-flex flex-row justify-content-center">-->
    <!--        <language-selector [showLabel]="true" class="d-xxl-none d-lg-none language-selector" placement="top-start"></language-selector>-->
    <!--        <theme-switcher></theme-switcher>-->
    <!--      </div>-->
    <div class="d-flex flex-row justify-content-center">
      <div class="version">{{ vrs }}</div>
    </div>

  </div>
</div>

