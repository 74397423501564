import { Routes } from '@angular/router';
import { SideMenuResolver } from '@bs24/cms/resolvers/menu.resolver';
import { GeoMainGuard } from '@bs24/core/guards/geo-main.guard';
import { LangGuard } from '@bs24/core/guards/lang.guard';
import { TranslateGuard } from '@bs24/core/guards/translate.guard';
import { PattyMenu } from '@bs24/core/models/menu';
import { environment } from '../environments/environment';

const optionalRoutes: Routes = [];


export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [TranslateGuard],
    children: [],
    pathMatch: 'full'
  },
  {
    path: ':lang',
    data: {
      langs: environment.languages,
      menuId: PattyMenu.MainNavigation
    },
    resolve: {
      menu: SideMenuResolver
    },
    canActivate: [LangGuard],
    canActivateChild: [GeoMainGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'games'
      },
      {
        path: 'games',
        data: {
          section: 'games'
        },
        loadChildren: () => import('./pages/games/games.module').then(m => m.GamesModule)
      },
      {
        path: 'register',
        loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule)
      },
      {
        path: 'me',
        loadChildren: () => import('./pages/me/me.module').then(m => m.MeModule)
      },
      {
        path: 'promotions',
        data: {
          section: 'promotions'
        },
        loadChildren: () => import('./pages/promotions/promotions.module').then(m => m.PromotionsModule)
      },
      {
        path: 'cms',
        loadChildren: () => import('./pages/cms/cms.module').then(m => m.CmsModule)
      },
      {
        path: 'sports',
        data: {
          section: 'sports'
        },
        loadComponent: () => import('./pages/sports/sports.main.page').then(p => p.SportsMainPage)
      },
      {
        path: 'sportsbook',
        data: {
          section: 'sportsbook'
        },
        loadChildren: () => import('./pages/sportsbook/sportsbook.module').then(m => m.SportsbookModule)
      },
      {
        path: 'install-app',
        loadComponent: () => import('./pages/install-app/install-app.page').then(c => c.InstallAppPage)
      },
      {
        path: 'affiliates',
        loadComponent: () => import('./pages/affiliates/affiliates.page').then(c => c.AffiliatesPage)
      },
      {
        path: 'e-sports',
        data: {
          section: 'e-sports'
        },
        loadComponent: () => import('./pages/e-sports/e-sports.main.page').then(m => m.ESportsMainPage)
      },
      {
        path: 'payments',
        loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule)
      },
      {
        path: 'self-contained',
        data: {
          selfContained: true
        },
        children: [
          {
            path: 'payment',
            children: [
              {
                path: 'success',
                loadComponent: () => import('./pages/self-contained/payments/payment-result.page').then(c => c.PaymentResultPage),
                data: {
                  section: 'success'
                }
              },
              {
                path: 'declined',
                loadComponent: () => import('./pages/self-contained/payments/payment-result.page').then(c => c.PaymentResultPage),
                data: {
                  section: 'declined'
                }
              }
            ]
          },
          {
            path: 'styleguide',
            //loadChildren: () => import('./pages/self-contained/styleguide/style-guide.module').then(m => m.StyleGuideModule)
            loadComponent: () => import('./pages/self-contained/styleguide/style-guide.component').then(p => p.StyleGuideComponent)
          }
        ]
      },
      {
        path: 'not-authorized',
        loadComponent: () => import('./pages/core/errors/errors.page').then(c => c.ErrorsPage),
        data: {
          section: 'not-authorized',
          title: 'notAuthorized',
          icon: 'banned'
        }
      },
      {
        path: 'auto-logout',
        loadComponent: () => import('./pages/core/errors/errors.page').then(c => c.ErrorsPage),
        data: {
          section: 'auto-logout',
          title: 'autoLogout',
          icon: 'banned'
        }
      },
      {
        path: 'not-found',
        loadComponent: () => import('./pages/core/errors/errors.page').then(c => c.ErrorsPage),
        data: {
          section: 'not-found',
          title: 'pageNotFound',
          icon: 'unlink'
        }
      },
      {
        path: 'country-restricted',
        loadComponent: () => import('./pages/core/errors/errors.page').then(c => c.ErrorsPage),
        data: {
          section: 'country-restricted',
          title: 'countryRestricted',
          icon: 'unlink'
        }
      },
      ...optionalRoutes,
      { path: '**', redirectTo: 'not-found' }
    ]
  }
];
