<ng-template #guestTpl>
  <div class="d-flex gap-1">
    <button class="btn btn-sm btn-outline-secondary" [routerLink]="[]" fragment="login" translate>login</button>
    <a class="btn btn-secondary btn-sm btn-gradient" routerLinkActive="active" [routerLink]="[lang,'register']" translate>signUp</a>
  </div>
</ng-template>


<ng-template #loggedTpl>
  <div class="d-flex align-items-center gap-50" ngbDropdown>
    <div ngbDropdownToggle>
      <svg icon="user-circle" class="fill-white" lib="regular" [size]="{w:32, h:32}"></svg>
    </div>

    <div class="mt-1 bg-primary border-0" aria-labelledby="dropdown-profile" ngbDropdownMenu>
      @for (item of loggedMenu.items; track $index) {
        <a [routerLink]="lang + '/' + item.routerLink" class="d-flex align-items-center" ngbDropdownItem routerLinkActive="active">
          @if (item.icon) {
            <svg [icon]="item.icon" [size]="{w: 12, h: 12}" class="me-50 fill-current"></svg>
          }
          <span>{{ item.label | translate }}</span>
        </a>
      }
      <button (click)="logout()" class="d-flex align-items-center w-100" ngbDropdownItem>
        <svg [size]="{w: 12, h: 12}" class="me-50 fill-current" icon="power-off"></svg>
        <span translate>logout</span>
      </button>
    </div>
    <balance mode="hover" class="font-small-1"></balance>
    <a class="btn btn-secondary btn-sm btn-gradient" routerLinkActive="active" [routerLink]="[lang, 'me','cashier', 'deposit']" translate>deposit</a>
  </div>
</ng-template>


<!--<div class="header-top-line d-none d-lg-flex align-items-center justify-content-between mx-50">-->
<!--  <patty-clock *ssr-norender></patty-clock>-->
<!--  <div><a [href]="'/en/install-app'">install-app</a></div>-->
<!--  <language-selector></language-selector>-->
<!--</div>-->

<div class="d-flex align-items-center justify-content-between mx-50">
  <ng-content select=".btn-open"></ng-content>
  <a class="flex-grow-1" [routerLink]="['/']">
    <img class="logo py-50" src="assets/icons/custom/logo-primary-bg.svg" alt="">
  </a>
  <menu [menu]="menu" mode="header" class="flex-grow-1 d-none d-lg-block pane small-bar "></menu>
  <ng-template [ngTemplateOutlet]="userProfile() ? loggedTpl:guestTpl"></ng-template>
</div>
<menu class="d-lg-none overflow-auto" [menu]="menu" mode="header"></menu>
