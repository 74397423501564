import { Component, Inject, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MenuService } from '@bs24/cms/services/menu.service';
import { SvgIconDirective } from '@bs24/core/directives/svg-icon.directive';
import { Language } from '@bs24/core/models/environment-config';
import { IMenuItem } from '@bs24/core/models/menu';
import { AuthService } from '@bs24/core/services/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs';

@Component({
  selector: 'bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
  standalone: true,
  imports: [RouterLinkActive, RouterLink, SvgIconDirective, TranslateModule]
})
export class BottomBarComponent {
  logged: WritableSignal<boolean> = signal(false);
  isSportPage: WritableSignal<boolean> = signal(false);
  notLogged: WritableSignal<boolean> = signal(true);

  menu: Array<IMenuItem> = [
    {
      routerLink: 'games/casino',
      icon: 'home'
    },
    {
      command: () => this.menuService.toggleVisibility('sportMenu'),
      icon: 'list',
      visible: this.isSportPage
    },
    {
      routerLink: 'register',
      icon: 'user-plus',
      className: 'flex-grow-1',
      visible: this.notLogged
    },
    {
      command: () => {
        alert('open chat');
      },
      icon: 'comment',
      visible: this.notLogged
    },
    {
      command: () => this.menuService.toggleVisibility('slip'),
      icon: 'angle-double-up',
      visible: this.isSportPage
    },
    {
      routerLink: 'me/history',
      icon: 'history',
      visible: this.logged
    },
    {
      routerLink: 'me/cashier/deposit',
      icon: 'money-bill',
      visible: this.logged
    },
    {
      routerLink: 'promotions',
      icon: 'gift',
      visible: this.logged
    }
  ];

  constructor(@Inject(Language) public lang: string, private menuService: MenuService, authService: AuthService, router: Router) {

    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
      next: (value: NavigationEnd) => {
        this.isSportPage.set(value.url.includes('sportsbook'));
      }
    });

    authService.accountLogged$.pipe(
      takeUntilDestroyed()
    ).subscribe({
      next: me => {
        this.logged.set(!!me);
        this.notLogged.set(!me);
      }
    });
  }
}
