import { LowerCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { SvgIconDirective } from '@bs24/core/directives/svg-icon.directive';
import { ILanguage } from '@bs24/core/models/currency';
import { EnvConfig, Language } from '@bs24/core/models/environment-config';

@Component({
  selector: 'language-selector',
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    NgbDropdownMenu,
    NgbDropdownItem,
    NgbDropdownToggle,
    LowerCasePipe,
    SvgIconDirective,
    NgbDropdown,
    TranslateModule
  ],
  standalone: true
})
export class LanguageSelectorComponent {

  selectedLanguage!: ILanguage;
  languages!: Array<ILanguage>;
  currentUrl!: string;

  @Input()
  showLabel = false;

  @Input()
  placement = 'bottom-end'

  constructor(enc: EnvConfig, @Inject(Language) public currentLang: string, router: Router, cd: ChangeDetectorRef) {
    // bootstrap.bootstrapConfig$.subscribe(b => {
    this.languages = enc.languages;
    // this.languages.forEach(l => l.label.toLocaleLowerCase().replace(' ', '-'));
    this.selectedLanguage = this.languages.find(l => l.code === this.currentLang) || this.languages[0];
    // });

    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
      next: () => {
        this.currentUrl = router.url;
        cd.markForCheck();
      }
    });
  }

  getLink(code: string): string {
    const url = this.currentUrl?.replace(/\/([a-z-]*)/, `/${code}`);
    return url ? url : code;
  }
}
