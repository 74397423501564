import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Inject, LOCALE_ID, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { TitleStrategy } from '@angular/router';
import { LocalStorage } from '@bs24/universal/universal.providers';
import { WINDOW } from '@bs24/universal/window.service';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';
import { Observable } from 'rxjs';
import { CustomPageTitleStrategy } from './helpers/custom-page-title.strategy';
import { EnvConfig, Language } from './models/environment-config';
import { SettingsService } from './services/settings.service';

const optionalProviders: Array<Provider> = [];

/**
 * handles the missing translations
 */
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  /**
   * the missingTranslations variable array
   */
  private missingTranslations: string[] = [];

  /**
   * A function that handles missing translations.
   */
  handle(params: MissingTranslationHandlerParams) {

    if (this.missingTranslations.indexOf(params.key) > -1) {
      return params.key;
    }
    this.missingTranslations.push(params.key);
    // console.log(params.key);
    console.log(`Translation missing: '${params.key}'`);
    return params.key;
  }

}

/**
 * service purpose is to fetch the translation
 */
export class TranslationLoaderService implements TranslateLoader {
  /**
   * the menu url from environment
   */
  readonly url = this.config.api.endpoint;

  /**
   * The constructor
   */
  constructor(private client: HttpClient, private settings: SettingsService, private config: EnvConfig) {
  }

  /**
   * function returns us the translations from the api
   */
  getTranslation(lang: string): Observable<any> {
    this.settings.saveSetting({languageCode: lang});
    return this.client.get(`${this.url}/core/translations`);
  }
}

function getLocale(config: EnvConfig): string {

  const languages = config.languages.map(l => l.code);
  const match = window.navigator.language.split('-')[0];

  if (languages.includes(match)) {
    return window.navigator.language;
  }

  return 'en';
}

@NgModule({
  declarations: [],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoaderService,
        deps: [HttpClient, SettingsService, EnvConfig]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      },
      useDefaultLang: true
    })
  ],
  exports: [
    TranslateModule
  ]
})
/**
 * The module reader
 */
export class TranslationModule {
  /**
   * The constructor where we set up the language
   */
  constructor(localStorage: LocalStorage, private config: EnvConfig, translate: TranslateService, @Inject(WINDOW) private window: Window,
              @Inject(LOCALE_ID) private locale: string, @Inject(DOCUMENT) private document: Document) {

    const refresh = this.window?.location?.pathname.split('/')[1];
    if (refresh) {
      localStorage.setItem('lang', refresh);
    }

    translate.onLangChange.subscribe({
      next: (data: LangChangeEvent) => localStorage.setItem('lang', data.lang)
    });

    const languages = this.config.languages.map(l => l.code);
    translate.addLangs(languages);

    const selected = this.config.languages.find(l => l.isDefault);

    if (!selected) {
      throw new Error('default language need to be defined, please add isDefault:true to one of the languages');
    }


    let lang;

    if (this.window) {
      const browserLang = localStorage.getItem('lang') || translate.getBrowserLang() || selected.code;
      lang = languages.includes(browserLang) ? browserLang : selected.code;

      optionalProviders.push({
        provide: LOCALE_ID,
        useValue: getLocale(this.config)
      });
    } else {
      lang = languages.includes(this.locale) ? this.locale : 'en';
    }

    /* const lang = [
       this.window?.location?.pathname.split('/')[1],
       localStorage.getItem('lang'),
       translate.getBrowserLang(),
       this.config.defaultLangCode,
     ].find(l => langs.includes(l));*/
    this.document.documentElement.lang = lang;
    translate.setDefaultLang(lang);
    translate.currentLang = lang;
    localStorage.setItem('lang', lang);

  }

  /**
   * forRoot separates providers from a module so we can import this module
   */
  static forRoot(): ModuleWithProviders<TranslationModule> {
    return {
      ngModule: TranslationModule,
      providers: [
        ...optionalProviders,
        {
          provide: Language,
          useFactory: () => inject(TranslateService).currentLang
        },
        {
          provide: TitleStrategy,
          useClass: CustomPageTitleStrategy
        }
      ]
    };
  }
}
