<div (mouseover)="onHover=true" (mouseleave)="onHover=false">
  <div class="text-secondary" translate>current-balance</div>
  <div>{{ userProfile().wallets[0].balance.available | moneyFormat }}</div>
</div>

@switch (mode) {
  @case ('exploded') {
    exploded
  }
  @case ('hover') {
    <div [ngClass]="{'d-none':!onHover}" class="position-absolute bg-primary border border-secondary p-1 hover rounded-4">
      <div>
        <span class="text-secondary font-small-2" translate>current-balance</span>:
        <span class="ps-25">{{ userProfile().wallets[0].balance.available | moneyFormat }}</span>
      </div>
      <div>
        <span class="text-secondary font-small-2" translate>bonus-balance</span>:
        <span class="ps-25">{{ bonusBalance | moneyFormat }}</span>
      </div>
    </div>
  }
}
