import { Component } from '@angular/core';
import { SvgIconDirective } from '@bs24/core/directives/svg-icon.directive';
import { LocalizedDatePipe } from '@bs24/core/pipes/localized-date.pipe';

@Component({
  templateUrl: './clock.component.html',
  selector: 'patty-clock',
  standalone: true,
  imports: [
    LocalizedDatePipe,
    SvgIconDirective],
  styles: [`:host {
    display: block;
  }`]
})
export class ClockComponent {

  currentTime = new Date();

  constructor() {
    setInterval(() => this.currentTime = new Date(), 1000);
  }
}
