import { DOCUMENT, NgClass, NgTemplateOutlet } from '@angular/common';
import { ApplicationRef, Component, HostBinding, Inject, isDevMode, Renderer2, TemplateRef } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { MenuService } from '@bs24/cms/services/menu.service';
import { SvgIconDirective } from '@bs24/core/directives/svg-icon.directive';
import { Settings } from '@bs24/core/models/app-settings';
import { Language } from '@bs24/core/models/environment-config';
import { ExternalScript } from '@bs24/core/models/externalScript';
import { IMenuItem } from '@bs24/core/models/menu';
import { AuthService } from '@bs24/core/services/auth.service';
import { DynamicScriptLoaderService } from '@bs24/core/services/dynamic-script-loader.service';
import { ProgressService } from '@bs24/core/services/progress.service';
import { SettingsService } from '@bs24/core/services/settings.service';
import { WINDOW } from '@bs24/universal/window.service';
import { NgbModal, NgbModalRef, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { concat, filter, first, interval, Observable, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { MenuComponent } from './components/menu/menu.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ToastsComponent } from './components/toast/toasts.component';
import { BottomBarComponent } from './views/bottom-bar/bottom-bar.component';
import { FooterComponent } from './views/footer/footer.component';
import { HeaderComponent } from './views/header/header.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    NgTemplateOutlet,
    RouterOutlet,
    BottomBarComponent,
    ToastsComponent,
    SpinnerComponent,
    SvgIconDirective,
    MenuComponent,
    NgClass
  ]
})
export class AppComponent {

  @HostBinding('class.no-scroll')
  no_scroll: boolean;

  isSelfContainedPage = false;

  sideMenu: IMenuItem;
  settings: Partial<Settings>;

  constructor(@Inject(DOCUMENT) document: Document, @Inject(Language) public lang: string, @Inject(WINDOW) private window: Window,
              updates: SwUpdate, appRef: ApplicationRef, route: ActivatedRoute, menuService: MenuService, appSettingsService: SettingsService, authService: AuthService,
              progressService: ProgressService, dsl: DynamicScriptLoaderService,
              public router: Router, private offCanvas: NgbOffcanvas, private modal: NgbModal, private renderer: Renderer2
  ) {

    appSettingsService.settings$.subscribe({
      next: (settings) => {
        this.settings = settings;
        this.renderer.setAttribute(document.body, 'data-bs-theme', settings.darkTheme ? 'dark' : 'light');
      }
    });

    this.settings = appSettingsService.settings();

    menuService.loadedSideMenu.subscribe(menu => {
      this.sideMenu = menu;
    });

    if (authService.isValid()) {
      authService.getAuthentication().subscribe();
    }


    route.data.subscribe(data => {
      this.isSelfContainedPage = data?.selfContained;
    });

    route.fragment.pipe(filter(fragment => !!fragment)).subscribe({
      next: fragment => this.modalSwitcher(fragment)
    });

    if (this.window) { // is browser

      const rooted = environment.externalScripts?.flatMap(link => link?.inRoot ? [link.name] : []);

      if (rooted?.length) {
        dsl.load(...rooted).forEach((tag: Observable<ExternalScript>) => {
          tag.subscribe();
        });
      }

      if (!isDevMode()) { // ssr
        updates.versionUpdates
          .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
          .subscribe({
            next: () => document.location.reload()
          });

        void updates.checkForUpdate();
        const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable));
        const every3Minutes$ = interval(3 * 60 * 1000);
        const every3MinutesOnceAppIsStable$ = concat(appIsStable$, every3Minutes$);

        every3MinutesOnceAppIsStable$.subscribe({
          next: async () => {
            if (!authService.isValid()) {
              try {
                const updateFound = await updates.checkForUpdate();
                if (updateFound) {
                  console.log('A new version is available.');
                }
                //console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
              } catch (err) {
                console.info('Failed to check for updates:', err);
              }
            }
          }
        });
      }

      router.events.pipe(
        filter(event => event instanceof NavigationStart || event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError),
        tap({next: event => progressService.toggleSpinner('global', event instanceof NavigationStart)})
      ).subscribe();
    }

  }

  open(content: TemplateRef<any>) {
    this.offCanvas.open(content, {ariaLabelledBy: 'offcanvas-basic-title', animation: true, panelClass: ''}).result.then(
      (_result) => {
        //this.closeResult = `Closed with: ${result}`;
      },
      (_reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  activate() {
    this.offCanvas.dismiss('router changed');
  }

  private async modalSwitcher(modalId: string | null) {

    let modalRef: NgbModalRef;

    switch (modalId) {
      case 'login':
        modalRef = await import('./modals/login/login.modal').then(c => {
          return this.modal.open(c.LoginModal, {centered: true, size: 'lg'});
        });
        break;
      case 'forgot-password':
        modalRef = await import('./modals/forgot-password/forgot-password.modal').then(c => {
          return this.modal.open(c.ForgotPasswordModal, {centered: true});
        });
        break;
    }

    modalRef?.result.then(result => {
      if (result?.redirect) {
        void this.router.navigate([result.redirect], {fragment: undefined, queryParamsHandling: 'merge'});
      } else {
        void this.router.navigate([], {fragment: undefined, queryParamsHandling: 'merge'});
      }
    }, result => {
      if (result?.dismiss) {
        void this.router.navigate([result.dismiss], {fragment: undefined, queryParamsHandling: 'merge'});
      } else if (result?.fragment) {
        void this.router.navigate([], {fragment: result.fragment});
      } else {
        void this.router.navigate([], {fragment: undefined, queryParamsHandling: 'merge'});
      }
    });
  }
}
