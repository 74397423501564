import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IFooter } from '@bs24/core/models/footer';
import { WebappService } from '@bs24/core/services/webapp.service';
import { Language } from '@bs24/core/models/environment-config';
import { ThemeSwitcherComponent } from '../../components/theme-switcher/theme-switcher.component';
import { LanguageSelectorComponent } from '../../components/language-selector/language-selector.component';
import { LicenseComponent } from '../../components/license/license.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbAccordionBody, NgbAccordionButton, NgbAccordionCollapse, NgbAccordionDirective, NgbAccordionHeader, NgbAccordionItem, NgbAccordionToggle, NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { SvgIconDirective } from '@bs24/core/directives/svg-icon.directive';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    SvgIconDirective,
    NgTemplateOutlet,
    NgbAccordionDirective,
    NgbAccordionItem,
    NgbAccordionHeader,
    NgbAccordionToggle,
    NgbAccordionButton,
    NgbCollapse,
    NgbAccordionCollapse,
    NgbAccordionBody,
    TranslateModule,
    LicenseComponent,
    LanguageSelectorComponent,
    ThemeSwitcherComponent,
    LowerCasePipe
  ]
})
export class FooterComponent implements OnInit, OnDestroy {

  footer: IFooter;
  vrs: string;
  subs = new Subscription();
  accordions: any = [
    { label: 'Sunbet', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et, varius metus. Nulla nec purus feugiat, molestie ipsum et, varius metus.' },
    { label: 'FAQs', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et, varius metus. Nulla nec purus feugiat, molestie ipsum et, varius metus.' },
    { label: 'Promotions', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et, varius metus. Nulla nec purus feugiat, molestie ipsum et, varius metus.' },
    { label: 'Regulations', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et, varius metus. Nulla nec purus feugiat, molestie ipsum et, varius metus.' }
  ];

  constructor(wa: WebappService, @Inject(Language) public lang: string) {
    this.footer = wa.webappConfig().footer;
  }

  ngOnInit(): void {
    this.vrs = environment.appVersion;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
