import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ProgressService } from '@bs24/core/services/progress.service';
import { Spin } from '@bs24/core/models/design';


@Component({
  selector: 'spinner[name]',
  styleUrl: './spinner.component.scss',
  encapsulation: ViewEncapsulation.None,
  template: '<div class="loader"></div>',
  standalone: true
})
export class SpinnerComponent implements OnInit {

  @Input()
  name!: string;

  @HostBinding('class.loading')
  loading!: boolean;

  @HostBinding('style.position')
  position!: 'absolute' | 'relative' | 'fixed';

  @HostBinding('style.display')
  display!: 'block' | 'none' | 'flex';

  constructor(private progressService: ProgressService) {
  }

  ngOnInit() {
    const instance = this.progressService.registerSpinner(this.name);
    instance.subscribe({
      next: (spin: Spin) => {
        this.loading = spin.loading;
      }
    });
  }
}
