import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { coreProviders } from '@bs24/core/core.providers';
import { TranslationModule } from '@bs24/core/translation.module';
import { CustomDateAdapter } from '@bs24/forms/helpers/custom-date-adapter';
import { universalProviders } from '@bs24/universal/universal.providers';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { appRoutes } from './app-routes';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      TranslationModule.forRoot(),
    ),
    universalProviders(),
    coreProviders(environment),
    provideClientHydration(),
    provideRouter(appRoutes,
      //withDebugTracing(),
      withInMemoryScrolling({scrollPositionRestoration: 'enabled'})
    ),
    provideServiceWorker('ngsw-worker.js', {enabled: !isDevMode(), registrationStrategy: 'registerWhenStable:30000'}),
    {
      provide: NgbDateAdapter,
      useClass: CustomDateAdapter
    }
  ]
};
