<ng-template [ngTemplateOutlet]="isSelfContainedPage ? selfContainedPageTpl : mainTpl"></ng-template>
<spinner name="global"></spinner>
<ng-template #mainTpl>
  @defer {
    <toasts aria-atomic="true" aria-live="polite"></toasts>
  }
  @if (!settings?.frameless) {
    <header>
      <button class="btn btn-open d-lg-none p-25" (click)="open(content)">
        <svg lib="solid" icon="bars" class="fill-white"></svg>
      </button>
    </header>
  }
  <main [class.container-xl]="!router.url.includes('cms/faq') && !settings?.frameless"
        [ngClass]="{'p-0 p-md-1': (!settings?.frameless && !router.url.includes('affiliates')), 'p-0': router.url.includes('affiliates')}">
    <router-outlet (activate)="activate()"></router-outlet>
  </main>
  @if (!settings?.frameless) {
    <footer class="d-flex flex-column"></footer>
  }
  <bottom-bar class="d-md-none position-fixed bottom-0 left-0 right-0 align-items-center"></bottom-bar>
</ng-template>

<ng-template #selfContainedPageTpl>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #content let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title" style="max-width: 160px">
      <img class="logo" src="assets/icons/custom/logo-primary-bg.svg" alt="" style="width:100%; height: 40px">
      <!--<svg  class="fill-current">
        <use xlink:href="assets/icons/custom/logo.svg#logo" class="logo fill-current"></use>
      </svg>-->
    </h4>
    <button (click)="offcanvas.dismiss('Cross click')" aria-label="Close" class="btn-close fill-current" tabindex="-1"
            type="button">&times;
    </button>
  </div>
  <div class="offcanvas-body">
    <!--    <ul class="nav justify-content-center">-->
    <!--      @for (item of menu.items; track item.id; let first = $first; let last = $last) {-->
    <!--        <li class="nav-item">-->
    <!--          <a #rla=routerLinkActive [routerLink]="item.routerLink" class="nav-link border-0 px-1" routerLinkActive="bg-primary"-->
    <!--             [class.rounded-start]="first" [class.rounded-end]="last"-->

    <!--             [ngClass]="{'text-dark bg-light':!rla.isActive}" [class.text-light]="rla.isActive">-->
    <!--            <svg [size]="{w:24, h:24}" [icon]="item.icon | lowercase" class="icon me-25" [class.fill-primary]="!rla.isActive" [class.fill-secondary]="rla.isActive" lib="menu"></svg>-->
    <!--            <span class="text-uppercase">{{ item.label }}</span>-->
    <!--          </a>-->
    <!--        </li>-->
    <!--      }-->
    <!--    </ul>-->
    <menu class="mt-4" [menu]="sideMenu" mode="side"></menu>
  </div>
</ng-template>
