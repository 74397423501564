import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Language } from '@bs24/core/models/environment-config';

/**
 * guard that on the first visit of the application sets the :lang param
 */
export const TranslateGuard: CanActivateFn = () => {

  const lang = inject(Language);
  const router = inject(Router);

  /**
   * interface guard deciding if a route can be activated, and it sets the :lang param
   */
  const currentNavigation = router.getCurrentNavigation();
  return router.navigate([lang], { queryParams: currentNavigation?.extractedUrl.queryParams });
};
